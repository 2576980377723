@font-face {
  font-family: "Blooms Regular";
  src: url("../fonts/blooms-regular.otf");
}

/* @font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham-bold.otf");
  font-weight: 600;
} */

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham-book.otf");
  font-weight: 400;
}

/* @font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham-medium.ttf");
  font-weight: 500;
} */

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham-light.otf");
  font-weight: 300;
}

:root {
  --color-primary: #002f57;
  --color-secondary: #ba9060;
  --color-text: #ba9060;
}

html {
  color: var(--color-text);
  font-size: 1em;
  line-height: 1.4;
  scroll-behavior: smooth;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

/*
 * Custom Style
 */

body {
  font-family: "Gotham", Arial, sans-serif;
  font-weight: 400;
}

.btn {
  box-shadow: 0 0 0 0.2rem transparent;
}

#scrolltop {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 30px;
  z-index: 999;
  cursor: pointer;
  padding: 10px 15px;
  background-color: var(--color-primary);
}

.btn-delivery {
  display: block;
  width: 275px;
  height: auto;
  text-align: center;
  position: fixed;
  bottom: 30px;
  right: calc(50% - 135px);
  z-index: 999;
  cursor: pointer;
  padding: 10px 15px;
  background-color: var(--color-secondary);
  font-size: 25px;
  color: white;
}

@media screen and (max-width: 1024px) {
  .btn-delivery {
    bottom: 10px;
    left: 15px;
  }
}

#icon-instagram,
#chevron-up {
  fill: var(--color-secondary);
}

.bg-color-default {
  background-color: var(--color-primary);
}

.color-default {
  color: var(--color-text);
}

.navbar-toggler {
  border-color: var(--color-secondary);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28186, 144, 96%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.container-default {
  background-color: var(--color-primary);
  color: var(--color-text);
  padding-top: 60px;
  padding-bottom: 60px;
}

.container-home {
  height: 95vh;
  background-image: url("../img/aima-background.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 1024px) {
  .container-home {
    background-position: 70%;
  }
}

.title {
  font-family: "Blooms Regular", Verdana, Tahoma, sans-serif;
  font-size: 2.5em;
}

.description {
  line-height: 1.8;
  color: var(--color-text);
}

.link:hover {
  line-height: 1.8;
  color: var(--color-text);
}

.link:active {
  line-height: 1.8;
  color: var(--color-text);
}

.link:focus {
  line-height: 1.8;
  color: var(--color-text);
}

.btn-primary {
  background-color: var(--color-secondary);
  color: #ffffff;
  border: 1px solid transparent;
}

.btn-primary:hover {
  background-color: var(--color-primary);
  color: var(--color-text);
  border: 1px solid var(--color-secondary);
}

.footer {
  margin-top: 60px;
  margin-bottom: 90px;
}

.footer p {
  font-size: 12px;
  margin: 0;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}
